import { useEffect, useState } from "react";
import styled from "styled-components";
import { API_BASE, TOASTPROPS } from "../shared/Constants";
import { useAuth0 } from "@auth0/auth0-react";
import { parseDate } from "../shared/Utils";
import { toast, ToastContainer } from "react-toastify";
import { Skeleton } from "@mui/material";


interface Model {
  max_tokens: number;
  token_rate_limit_input: number;
  token_rate_limit_output: number;
  requests_per_minute: number;
  input_price: number;
  output_price: number;
  update_date: string;
  [key: string]: any;
}

interface ModelList {
  [modelName: string]: Model;
}

interface Feature {
  key: keyof Model;
  label: string;
  format: (value: any) => string;
}

function Models() {
  const [loading, setLoading] = useState<boolean>(true);
  const [models, setModels] = useState<ModelList>({});
  const { getAccessTokenSilently } = useAuth0();

  const parsePrice = (price: number): string => {
    let parsedPrice = (price * 1e6).toFixed(2);
    parsedPrice = parsedPrice.replace(".", ",");
    return parsedPrice;
  };

  const parseTokenRateLimit = (rate: number): string => {
    if (rate >= 1000000) {
      const millionRate = rate / 1000000;
      const formattedRate =
        millionRate % 1 === 0
          ? millionRate.toFixed(0)
          : millionRate.toFixed(1).replace(".", ",");
      return `${formattedRate} ${millionRate > 1 ? "milhões" : "milhão"}`;
    } else if (rate >= 1000) {
      return `${(rate / 1000).toFixed(0)} mil`;
    } else {
      return rate.toString();
    }
  };

  useEffect(() => {
    const getModels = async () => {
      try {
        setLoading(true);
        const header = new Headers();
        const token = await getAccessTokenSilently();
        header.append("Authorization", `Auth ${token}`);
        const response = await fetch(`${API_BASE}/api/chat/models`, {
          method: "GET",
          headers: header,
        });
        if (!response.ok) {
          throw new Error("Failed to fetch models");
        }
        const data = await response.json();
        setModels(data["models"]);
        setLoading(false);
      } catch (error) {
        toast.error("Erro ao recuperar modelos disponíveis.", TOASTPROPS);
        setLoading(false);
      }
    };
    getModels();
  }, [getAccessTokenSilently]);

  const features: Feature[] = [
    {
      key: "max_tokens",
      label: "Contexto Máximo",
      format: (value: number) => `${value} tokens`,
    },
    {
      key: "token_rate_limit_input",
      label: "Rate limit - Entrada [TPM]",
      format: parseTokenRateLimit,
    },
    {
      key: "token_rate_limit_output",
      label: "Rate limit- Saída [TPM]",
      format: parseTokenRateLimit,
    },
    {
      key: "requests_per_minute",
      label: "Requisições [RPM]",
      format: (value: number) => value.toString(),
    },
    {
      key: "input_price",
      label: "Entrada",
      format: (value: number) => `R$ ${parsePrice(value)}`,
    },
    {
      key: "output_price",
      label: "Saída",
      format: (value: number) => `R$ ${parsePrice(value)}`,
    },
    {
      key: "update_date",
      label: "Data de Lançamento",
      format: parseDate,
    },
    {
      key: "knowledge_cutoff",
      label: "Conhecimento",
      format: (value: string) => value,
    }
  ];

  return (
    <Container>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th></th>
              {loading &&
                Array.from(new Array(4)).map((_, index) => (
                  <th key={index}>
                    <Skeleton variant="text" width={100} />
                  </th>
                ))}
              {!loading &&
                Object.keys(models).map((key) => (
                  <th key={key}>{key}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {features.map((feature) => (
              <tr key={feature.key as string}>
                <td>
                  <strong>{feature.label}</strong>
                </td>
                {loading &&
                  Array.from(new Array(4)).map((_, index) => (
                    <td key={index}>
                      <Skeleton variant="text" width={100} />
                    </td>
                  ))}
                {!loading &&
                  Object.keys(models).map((key) => {
                    const model = models[key];
                    const value = model[feature.key];
                    return (
                      <td key={key}>{feature.format(value)}</td>
                    );
                  })}
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    <Text>
      TPM = Tokens por minuto. <br /> RPM = Requisições por minuto.
    </Text>
      <Warning>
        O modelo sabia-2-small será descontinuado em 15/01/2025.
        Recomendamos migrar para o sabiazinho-3.
      </Warning>
      <ToastContainer />
    </Container>
  );
}

export default Models;

const Warning = styled.p`
  font-size: 1.1rem;
  color: ${(props) => props.theme.red};
  font-family: Plus Jakarta Sans, sans-serif;
  line-height: 1.7rem;
  margin-top: 0px;
  margin-bottom: 20px;
`;

const TableContainer = styled.div`
  overflow-x: auto;
  min-height: 150px;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.white_background};
`;

const Text = styled.p`
  font-size: 1.1rem;
  font-weight: 350;
  color: ${(props) => props.theme.dark_blue};
  font-family: Plus Jakarta Sans, sans-serif;
  line-height: 1.7rem;

  @media (max-width: 768px) {
    padding-right: 15px;
  }
`;

const Table = styled.table`
  max-width: 100%;

  th,
  td {
    text-align: left;
    padding-top: 15px;
  }

  th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }

  th:nth-child(1),
  td:nth-child(1) {
    min-width: 250px;
    padding-right: 20px;
  }

  th:not(:first-child),
  td:not(:first-child) {
    min-width: 130px;
    padding-right: 20px;
  }

  th:last-child,
  td:last-child {
    padding-right: 5px;
  }

  @media (max-width: 768px) {
    th:nth-child(1),
    td:nth-child(1) {
      min-width: 0px;
      padding-right: 20px;
    }

    th:not(:first-child),
    td:not(:first-child) {
      min-width: 0px;
    }

    th:last-child,
    td:last-child {
      min-width: 0px;
      padding-right: 20px;
    }
  }

  font-size: 1.1rem;
  font-weight: 400;
  color: ${(props) => props.theme.dark_blue};
  font-family: Plus Jakarta Sans, sans-serif;
`;
